import React, { Component, useRef } from 'react'
import { skillData } from './Data/data.js'

import './App.css';
import LoadingScreen from './components/Loading/Loading'
import Aux from './HOC/Auxillary/Auxillary'
import Toolbar from './components/Toolbar/Toolbar'
import Skills from './containers/Skills/Skills'
import Porfolio from './containers/Portfolio/Portfolio'
import Close from './components/UI/Close/Close'
import gsap from 'gsap'

import { Route, Link } from 'react-router-dom';

class App extends Component {
  constructor() {
    super()
    this.state = {
      currentSection: 0,
      isLoading: true,
      arSection: false,
    }
    this.loadingScreen = null
  }

  setCurrentSection = (selectedSection) => {
    this.setState({currentSection: selectedSection, portfolioSection: true})
  }

  //Remove loading screen once page has loaded.
  componentDidMount() {
    setTimeout(() => {
      gsap.to(this.loadingScreen, { 
        opacity: 0, 
        duration: 1, 
        delay: 0, 
        onComplete: this.setLoadingState() })
    }, 1000)
  }

  setLoadingState() {
    setTimeout(() => {
      this.setState({ isLoading: false })
    }, 1000)
  }

  //Set loading screen for index page on mount
  closePortfolioHandler = () => {
    this.setState({ isLoading: true })
    setTimeout(() => {
      this.setState({ isLoading: false })
    }, 1000)
  }

  render() {
    return (
      <div className="App">
        {this.state.isLoading && (
          <div ref={div => this.loadingScreen = div} >
            <LoadingScreen />
          </div>
        )}
        {!this.state.isLoading && (
          <Aux>
            <Toolbar />
              <Route exact path="/" render={()=>
                <Skills
                  skillData={skillData}
                  setSection = {this.setCurrentSection}
                />
              }/>
              <Route exact path={skillData[this.state.currentSection].url} render={()=>
                <>
                  <Link
                    to="/"
                  >
                    <Close
                      className={"porfolioCloseBtn"}
                      text={"main menu"}
                      closePortfolioHandler ={this.closePortfolioHandler}
                    />
                  </Link>
                  <Porfolio
                    property={skillData[this.state.currentSection]}
                  />
                </>
              } />
          </Aux>
        )} 
      </div>
    );
  }

}

export default App;
