import React from 'react'
import './Logo.css'
import siteLogo from '../../assets/images/bs-logo.svg'

const logo = () => {
    return(
        <div>
            <img src={siteLogo} className="bsLogo" alt="logo" />
        </div>
    )
}

export default logo
