import React, { useRef, useEffect, useState } from 'react'
import Porfolio from '../../Portfolio/Portfolio'

import './Skill.scss'

import { useHistory } from 'react-router-dom';

import CSSRulePlugin from 'gsap/CSSRulePlugin';
import { Power2, TimelineLite } from 'gsap'
import { gsap } from 'gsap'

const Skill = (props) => {
    
  
    const { index, videoPreview, mainClass, className, graphic, name, secName, shouldHide, wipedelay} = props.property;
    
    let containerRef = useRef(null);
    let skillRef = useRef(null);
    let skillName = useRef(null);
    let nameWrapper = useRef(null);
    let portiWrapper = useRef(null);

    gsap.registerPlugin(CSSRulePlugin);

    const skilImageWrapper1 = CSSRulePlugin.getRule(".skillImageWrapper");
    const skilImageWrapper2 = CSSRulePlugin.getRule(".skillImageWrapper2");
    const skilImageWrapper3 = CSSRulePlugin.getRule(".skillImageWrapper3");
    
    const nameBackground = CSSRulePlugin.getRule(".nWrapper")

    // Main 3 blocks intro animation
    useEffect(() => {
        gsap.to(containerRef, { duration: 0, css: { visibility: "visible" } })
        gsap.to(skilImageWrapper1, { duration: 1, width: "100%", ease: Power2.easeOut, delay: 0.5 })
        gsap.to(skilImageWrapper2, { duration: 1, width: "100%", ease: Power2.easeOut, delay: 1 })
        gsap.to(skilImageWrapper3, { duration: 1, width: "100%", ease: Power2.easeOut, delay:1.5 })
        gsap.from(skillRef, { duration: 1.5, scale: 1.6, ease: Power2.easeInOut, delay: wipedelay })
    
    }, [])

    //Roll over animation removing block title
    useEffect(() => {
        props.showPorfolioItems ?
            gsap.to(nameWrapper, { duration: 1, width:"0%", ease: Power2.easeInOut })
            : gsap.to(nameWrapper, { duration: 1, width:"110%", ease: Power2.easeInOut })
    }, [props.showPorfolioItems])

    const history = useHistory();

    const togglePorti = () => {
        props.updateSection();
        props.porfolioVisible(index, props.toggleSection);
        setTimeout(()=> {
            history.push(props.url);
        },1000)

        gsap.to(containerRef, { duration: 0.3, scale:1, ease:Power2.easeIn })
    }

    const toggleGalleryInformationHandler = () => {  
        props.toggleGalleryInformationHandler();
    }


    const outImg = () => {
        props.toggleVideoPreviewOff(); 
        gsap.to(containerRef, { duration: 0.2, scale:1, ease:Power2.easeIn })
        gsap.to(nameWrapper, { duration: 0.7, width:"110%", ease: Power2.easeInOut })
    }

    const overImg = () => {
        props.toggleVideoPreview(props.section)
        const js = JSON.stringify(props.section)
        gsap.to(containerRef, { duration: 0.3, scale:0.95, ease:Power2.easeIn })
        gsap.to(nameWrapper, { duration: 0.7, width:"0%", ease: Power2.easeInOut })
    }
    

    return (
        <div
            ref={el => containerRef = el}
            className={`viewMore ${mainClass}`}
            onMouseEnter={!props.showPorfolioItems ? overImg  : null}
            onMouseLeave={!props.showPorfolioItems ? outImg : null}
            
            onClick={()=>{
                togglePorti()
                toggleGalleryInformationHandler()
                } 
            }
        >
            <div className={className}  ref={el => portiWrapper = el}>
                <img className="skillsHome" ref={el => skillRef = el} src={graphic} />
                {props.currentSection && props.showVideoPreview && ( 
                    <div className="video">
                        <video width='450px' height='700px' autoPlay muted loop>
                            <source src={videoPreview} />
                            Your browser does not support the video tag.
                        </video>
                    </div>  
                )}       
            </div>
            <div ref={el => nameWrapper = el} className="nameWrapper">
                <div ref={skillName} className="nWrapper">
                    <div className="nameBackground">
                        <h1 className="name-text">{name}</h1>
                    </div>
                </div>
                <div className={shouldHide ? "hidden" : "nWrapper"}>
                    <div className="nameBackground">
                        <h1 className="name-text">{secName}</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skill