import React, { useRef, useEffect, useState } from 'react'
import Aux from '../../HOC/Auxillary/Auxillary'
import gsap, { Power2, Elastic, TimelineMax, Linear } from 'gsap/gsap-core'

import Lottie from 'react-lottie-player'

import Slider from 'react-slick'
import CSSRulePlugin from 'gsap/CSSRulePlugin';

import '../Portfolio/Porfolio.css'

import viewvideoinner from '../../assets/ui/viewVideoInner.svg'
import animationData from '../../assets/ui/arrowAni.json'
import animationDataHover from '../../assets/ui/arrowAniHover.json'

const Client = (props) => {

    const mainHeader = useRef(null);
    const portfolioImage = useRef(null);
    const campaingHeader = useRef(null);
    const secondaryHeader = useRef(null);
    const campaingCopy = useRef(null);
    const viewVideo = useRef(null);
    const viewVideoInner = useRef(null);
    const prev = useRef(null);
    const next = useRef(null);

    const animation = useRef(null);

    //Register all gsap plugins
    gsap.registerPlugin(CSSRulePlugin);

    //All client information data for images
    const gallery = props.galleryList

    const [rollOver, toggleRollOver] = useState(false);

    //Click event to toggle gallery images and animation
    const [showGallery, toggleshowGallery] = useState(false);

    const [showVideoIcon, toggleShowVideoIcon] = useState(false);
    const [showPreviousHover, toggleShowPreviousHover] = useState(false);
    const [showNextHover, toggleShowNextHover] = useState(false);

    const imgWrapper = CSSRulePlugin.getRule(".slick-slide div");
    const sliderArrows = CSSRulePlugin.getRule(".sliderArrows");
    const right = CSSRulePlugin.getRule(".right");  
    const left = CSSRulePlugin.getRule(".left");  
    const prevVis = CSSRulePlugin.getRule(".prev");  
    const nextVis = CSSRulePlugin.getRule(".next");  
  
    let delay = 0.1
    const currentClientIndex = props.currentClientIndex;

    const revealRef = useRef([]);
    revealRef.current = []

    const addToRefs = (el) => {
        if (el && !revealRef.current.includes(el)) {
            revealRef.current.push(el)
        }
    }

    //Split header text into single letters and animate 
    const headerLetters = props.header.split(/(\s+)/);

    const headerRef = useRef([])
    headerRef.current = []

    const addHeadersToRef = (el) => {
        if (el && !headerRef.current.includes(el)) {
            headerRef.current.push(el)
        }
    }

    // Animate headers into window on load
    useEffect(() => {
        gsap.set(imgWrapper, { delay: 0, width: "0%", duration: 0, opacity: 0, }, 0);
        gsap.set(secondaryHeader.current, { delay: 0, duration: 0, opacity: 0, x: "-70px" }, 0)
        gsap.set(campaingCopy.current, { delay: 0, duration: 0, opacity: 0, y: "50px" }, 0)
        if (prev.current != null) {
            gsap.set(prev.current, { delay: 0, duration: 0, opacity: 0, x: "50px" })
            gsap.set(next.current, { delay: 0, duration: 0, opacity: 0, x: "-50px" })
        }
        
        if (prev.current != null) {
            gsap.to(prev.current, { delay: 0, duration: 0, opacity: 0, x: "50px" }, 0)
            gsap.to(next.current, { delay: 0, duration: 0, opacity: 0, x: "-50px" }, 0)
        }
        if (viewVideoInner.current != null) {
            gsap.set(viewVideoInner.current, { opacity: 0, duration: 0, scale: 0.1 }, 0);
            gsap.set(viewVideo.current, { opacity: 0, duration: 0, scale: 0.1 }, 0);

        }
        setTimeout(() => {
            toggleshowGallery(false)
            if (sliderArrows != null) {
                gsap.to(sliderArrows, {delay: 1, visibility: "visible", duration: 1 },1);
            }
        },1000)
        setTimeout(() => {

            if (prev.current != null) {
                gsap.fromTo(prev.current, { opacity: 0, x: "50px" }, { delay: 1, opacity: 1, x: "0px", duration: 1, ease: Power2.easeOut },1)
                gsap.fromTo(next.current, { opacity: 0, x: "-50px" }, { delay: 1, opacity: 1, x: "0px", duration: 1, ease: Power2.easeOut },1)
            }

            if (right.current != null) {
                gsap.fromTo(right.current, { opacity: 0}, { delay: 1, opacity: 1,  duration: 1, ease: Power2.easeOut },1)
                gsap.fromTo(left.current, { opacity: 0}, { delay: 1, opacity: 1,  duration: 1, ease: Power2.easeOut },1)
            }
            if (viewVideoInner.current != null) {
                gsap.fromTo(viewVideo.current, { rotation: 0 }, { rotation: 360, duration: 15, repeat: -1, ease: "linear" }, 0)
            }

            gsap.fromTo(secondaryHeader.current, { opacity: 0, x: "-70px" }, { delay: 1, opacity: 1, x: "0px", duration: 1, ease: Power2.easeOut })
            gsap.fromTo(imgWrapper, { width: "0%", opacity: 0 }, { width: "100%", opacity: 1, duration: 0.5 }, 0);
            gsap.fromTo(campaingCopy.current, { opacity: 0, y: "50px" }, { delay: 1.5, opacity: 1, y: "0px", duration: 1, ease: Power2.easeOut })

            headerRef.current.forEach((el, index) => {
                gsap.to(el, {
                    y: "0px",
                    opacity: 1,
                    duration: 1,
                    delay: Math.floor(index) * 0.1,
                    // ease: Power2.easeOut
                })
            })
            if (viewVideoInner.current != null) {
                gsap.fromTo(viewVideoInner.current, { opacity: 0, scale: 0.2 }, { opacity: 1, scale: 1, duration: 1, ease: Elastic.easeOut.config(1, .8) });
                gsap.fromTo(viewVideo.current, { opacity: 0, scale: 0.3 }, { opacity: 1, scale: 1, delay: 0.2, duration: 1, ease: Elastic.easeOut.config(1, .8) });
            }

        }, 1000)
    }, []);

    // Animate headers out window
    useEffect(() => {

        gsap.set(secondaryHeader.current, { opacity: 0, x: "-70px" })
        headerRef.current.forEach((el, index) => {
            gsap.to(el, {
                y: "150px",
                opacity: 0,
                duration: 1,
                delay: Math.floor(index) * 0.1,
                // ease: Power2.easeOut
            })
        })
        gsap.fromTo(imgWrapper, { width: "100%" }, { delay: 0, width: "0%", duration: 0.5 });
        gsap.to(campaingCopy.current, { delay: 0, opacity: 0, y: "50px", duration: 1, ease: Power2.easeOut })
        if (prev.current != null) {
            gsap.to(prev.current, { delay: 0, opacity: 0, x: "50px", duration: 1, ease: Power2.easeOut })
            gsap.to(next.current, { delay: 0, opacity: 0, x: "-50px", duration: 1, ease: Power2.easeOut })
        }
        if (viewVideoInner.current != null) {
            gsap.to(viewVideoInner.current, { opacity: 0, scale: 0.2, duration: 1, ease: Elastic.easeOut.config(1, .8) });
            gsap.to(viewVideo.current, { opacity: 0, scale: 0.3, delay: 0.2, duration: 1, ease: Elastic.easeOut.config(1, .8) });
        }
        setTimeout(() => {
            if (sliderArrows.current != null) {
                gsap.to(sliderArrows, { visibility: "hidden"  });
            }
        }, 500)
    }, [!props.closeGalleryInformation]);


    const [loading, setLoading] = useState(true);


    // Lottie arrow animations settings

    const updatePrevious = () => {
        const currentState = showPreviousHover;
        toggleShowPreviousHover(!currentState)
    }

    const updateNext = () => {
        const currentHoverState = showNextHover;
        toggleShowNextHover(!currentHoverState);
    }


    //Custom arrows slick slider.
    function NextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={`right ${!showGallery ? "sliderArrows" : ""}`}
                onClick={onClick}
                onMouseEnter={() => updatePrevious()}
                onMouseLeave={() => updatePrevious()}
                ref={prev}
            >
                <div
                    className="prev slick-arrow"
                    onClick={onClick}

                >
                    <Lottie
                        className="rightArrow"
                        loop={false}
                        play={showPreviousHover}
                        animationData={animationDataHover}
                    />
                    <p className="sliderArrows">previous</p>
                </div>
            </div>
        );
    }

    function PrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div

                className={`left ${!showGallery ? "sliderArrows" : ""}`}
                onClick={onClick}
                onMouseEnter={() => updateNext()}
                onMouseLeave={() => updateNext()}
                ref={next}
            >
                <div className="next slick-arrow" onClick={onClick} >
                    <p className="sliderArrows">next</p>
                    <Lottie
                        className="leftArrow"
                        loop={false}
                        play={showNextHover}
                        animationData={animationDataHover}
                    />
                </div>
            </div>
        );
    }

    //Slick sliders settings
    let settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        initialSlide: 0,
        nextArrow: <PrevArrow />,
        prevArrow: <NextArrow />,
        draggable: true,

        rtl: false
    }
    const playVideo = () => {
        props.playVideo(currentClientIndex)
    }

    const videoOver = () => {
        if (viewVideoInner.current != null) {
            gsap.to(viewVideo.current, { duration: 1, scale: 1.2, ease: Elastic.easeOut.config(1.5, .3) });
        }
    }

    const videoLeave = () => {
        if (viewVideoInner.current != null) {
            gsap.to(viewVideo.current, { duration: 1, scale: 1, ease: Elastic.easeOut.config(1.5, .3) });
        }
    }

    return (
        <Aux>
            <div className='row masterWrap'>
                {!showGallery && (
                <div className=" client-gallery"
                    dir="rtl"
                    key={props.header}
                // onClick={()=> !props.showClientGallery ? toggleGallery() : null } 
                >
                    <Slider
                        className="portfolioImages scrollHover"
                        {...settings}
                        ref={portfolioImage}
                    >
                        {gallery.map((value, index) => {
                            return (
                                <div
                                    className={`porfolioSingle ${!props.showClientGallery ? "" : "scrollHover"}`}
                                    key={index}
                                    ref={addToRefs}
                                >
                                    <img src={value} />
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                )}
                {!showGallery && (
                <div className={`projectCopy col-4 ${!props.showClientGallery ? "" : "hidden"}`} >
                    <h2 className="header" ref={mainHeader}>
                        {headerLetters.map((value, index) => {
                            return <span className="singleHdrLetter" ref={addHeadersToRef} key={index}>{value}</span>
                        })}
                    </h2>
                    <h4 className="projectDescription" ref={secondaryHeader}>{props.projectInfo}</h4>
                    <div className="campaignInfo" >
                        <div className="campaingCopy" ref={campaingCopy} >
                            <p>{props.description}</p>
                            <h6>{props.software}</h6>
                        </div>
                    </div>
                    {props.isVideo && (
                        <div className="video-links">
                            <h6>{props.isLink ? "view site" : "view video"}</h6>
                            <div
                                className="viewVideoWrapper"
                                data-icon="P"
                                aria-label="play pause toggle"
                                value={currentClientIndex}
                                onClick={(e) => {
                                    props.isLink ? props.openLink() : playVideo()
                                }}
                                onMouseEnter={() => videoOver()}
                                onMouseLeave={() => videoLeave()}
                            >
                                <img className="viewVideoInner" src={viewvideoinner} ref={viewVideoInner} />
                                <img className="viewVideo" src={props.viewMoreButton} ref={viewVideo} />
                            </div>
                        </div>
                    )}
                </div>
                )}
            </div>
        </Aux>
    )
}

export default Client