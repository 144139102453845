
import arImg from '../assets/images/section-ar.jpg'
import devImg from '../assets/images/section-dev.jpg'
import aniImg from '../assets/images/section-3d.jpg'
import viewvideo from '../assets/ui/viewVideo.svg'
import viewInter from '../assets/ui/viewInter.svg'

//AR VR Animation images and video
import arPreview from '../assets/videos/AR01-01.mp4'
import devPreview from '../assets/videos/Dev-01.mp4'
import aniPreview from '../assets/videos/Ani-01.mp4'
import ar01_01 from '../assets/images/ar/01/p01-01.jpg'
import ar01_02 from '../assets/images/ar/01/p01-02.jpg'
import ar01_03 from '../assets/images/ar/01/p01-03.jpg'
import ar01_04 from '../assets/images/ar/01/p01-04.jpg'
import ar01_05 from '../assets/images/ar/01/p01-05.jpg'
import ar02video from '../assets/videos/Guestinationsn.mp4'
import ar02_01 from '../assets/images/ar/02/p02-01.jpg'
import ar02_02 from '../assets/images/ar/02/p02-02.jpg'
import ar02_03 from '../assets/images/ar/02/p02-03.jpg'
import ar02_04 from '../assets/images/ar/02/p02-04.jpg'
import ar02_05 from '../assets/images/ar/02/p02-05.jpg'
import ar02_06 from '../assets/images/ar/02/p02-06.jpg'
import ar03_01 from '../assets/images/ar/03/p03-01.jpg'
import ar03_02 from '../assets/images/ar/03/p03-02.jpg'
import ar03_03 from '../assets/images/ar/03/p03-03.jpg'
import ar03_04 from '../assets/images/ar/03/p03-04.jpg'
import ar03_05 from '../assets/images/ar/03/p03-05.jpg'
import ar03_06 from '../assets/images/ar/03/p03-06.jpg'
import ar04_01 from '../assets/images/ar/04/p04-01.jpg'
import ar04_02 from '../assets/images/ar/04/p04-02.jpg'
import ar04_03 from '../assets/images/ar/04/p04-03.jpg'
import ar04_04 from '../assets/images/ar/04/p04-04.jpg'
import ar04_05 from '../assets/images/ar/04/p04-05.jpg'

//Dev and interactive images

import dev01_01 from '../assets/images/dev/01/d01-01.jpg'
import dev01_02 from '../assets/images/dev/01/d01-02.jpg'
import dev01_03 from '../assets/images/dev/01/d01-03.jpg'
import dev01_04 from '../assets/images/dev/01/d01-04.jpg'
import dev01_05 from '../assets/images/dev/01/d01-05.jpg'
import dev02_01 from '../assets/images/dev/02/d02-01.jpg'
import dev02_02 from '../assets/images/dev/02/d02-02.jpg'
import dev02_03 from '../assets/images/dev/02/d02-03.jpg'
import dev02_04 from '../assets/images/dev/02/d02-04.jpg'
import dev02_05 from '../assets/images/dev/02/d02-05.jpg'
import dev02_06 from '../assets/images/dev/02/d02-06.jpg'
import dev02_07 from '../assets/images/dev/02/d02-07.jpg'
import dev03_01 from '../assets/images/dev/03/d03-01.jpg'
import dev03_02 from '../assets/images/dev/03/d03-02.jpg'
import dev03_03 from '../assets/images/dev/03/d03-03.jpg'
import dev03_04 from '../assets/images/dev/03/d03-04.jpg'
import dev03_05 from '../assets/images/dev/03/d03-05.jpg'
import dev04_01 from '../assets/images/dev/04/druces01.jpg'
import dev04_02 from '../assets/images/dev/04/druces02.jpg'
import dev04_03 from '../assets/images/dev/04/druces03.jpg'

// Video and Animation
import vid01 from '../assets/videos/tideway.mp4'
import v01_01 from '../assets/images/video/01/v01-01.jpg'
import vid02 from '../assets/videos/zinc.mp4'
import v02_01 from '../assets/images/video/02/v02-01.jpg'
import vid03 from '../assets/videos/bodyshop.mp4'
import v03_01 from '../assets/images/video/03/v03-01.jpg'
import vid04 from '../assets/videos/ctc.mp4'
import v04_01 from '../assets/images/video/04/v04-01.jpg'

export const skillData = [
    {
        index: 0,
        section: "arSection",
        url: "/ar",
        videoPreview: arPreview,
        mainClass: "skillWrapper1",
        className: "skillImageWrapper",
        name: "AR / VR / 3D",
        shouldHide: true,
        graphic: arImg,
        wipedelay: 0,
        width: 33.3,
        porfolio: {
            client01: {
                header: "Jacobs",
                projectInfo: "- Augmented Reality",
                descriptionHeading: "The brief",
                description: "This fictional pipeline was developed to educate users on how tunnels are created and the potential impact on the environment",
                software: "unity /  blender /  arCore / arKit / app store / play store",
                isVideo: false,
                videoLink: null,
                viewMoreButton: null,
                isVisible: false,
                img1:ar03_01,
                images: [
                    ar03_04,
                    ar03_05,                  
                    ar03_06, 
                    ar03_01,
                    ar03_02,
                    ar03_03,
                ],  
            },
            client02: {
                header: "Lufthansa",
                projectInfo: "- Virtual Reality",
                descriptionHeading: "The brief",
                description: "Using a mobile app, Google Cardboard and Google Streetview, users viewed top worldwide destinations in 360 and had to guess where they were. The project is a unique combination of VR and mobile app development using Unity 3D. It was a perfect fit for Lufthansa as using Google Cardboard allowed for a cheap alternative to other expensive VR products. ",
                software: "unity / maya / after effect / web development / app store / play store",
                isVideo: true,
                videoLink: ar02video,
                viewMoreButton: viewvideo,
                isVisible: false,
                img1:ar02_01,
                images: [
                    ar02_01,
                    ar02_02,
                    ar02_03,
                    ar02_04,
                    ar02_05,
                    ar02_06,
                ],
            },
            client03: {
                header: "HS2",
                projectInfo: "- Augmented Reality",
                descriptionHeading: "The brief",
                description: "HS2 were looking to expand thier educations offering along the route of the High Speed rail. The combination of AR technology with the educational content creates a unique learning experience for children in Keystage 1-2. We created an app to educate users on multiple job roles available within HS2 with information based on their skills and day to day tasks. ",
                software: "unity /  blender /  vuforia / app store / play store",
                isVideo: false,
                videoLink: null,
                viewMoreButton: null,
                isVisible: false,
                img1:ar04_01,
                images: [
                    ar04_01,
                    ar04_02,
                    ar04_03,
                    ar04_04,
                    ar04_05,
                 ],
            },
            client04: {
                header: "Siemens",
                projectInfo: "- Augmented Reality",
                descriptionHeading: "The brief",
                description: "We were approached by Siemens to create a center piece for use in the multiple Education expos they are involved with. An Augemented Reality poster was choosen to create excitement and showcase various technologies Siemens has created or has been involved with developing. The introduction and use of AR in education create fun and excitement for students and the ability to navigate around the 3D models created the desired affect.",
                software: "unity /  blender /  arCore / arKit / app store / play store",
                isVideo: false,
                videoLink: ar02video,
                viewMoreButton: viewvideo,
                isVisible: true,
                img1:ar01_01,
                images: [
                    ar01_01,
                    ar01_02,
                    ar01_03,
                    ar01_04,
                    ar01_05                    
                ],
            },
            
            // client05: {
            //     header: "CTC",
            //     projectInfo: "- 3D Modeling",
            //     descriptionHeading: "The brief",
            //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            //     software: "unity /  blender ",
            //     isVideo: false,
            //     videoLink: null,
            //     viewMoreButton: null,
            //     isVisible: false,
            //     img1:ar01_03,
            //     images: [
            //         ar01_02,
            //         ar01_03,
            //         ar01_01,
            //     ],
            // },
        }
    },
    {
        index: 1,
        section: "devSection",
        url: '/dev',
        mainClass: "skillWrapper2",
        className: "skillImageWrapper2",
        name: "DEV &",
        secName: "INTERACTIVES",
        videoPreview: devPreview,
        shouldHide: false,
        graphic: devImg,
        wipedelay: 0.5,
        width: 33.3,
        porfolio: {
            client01: {
                header: "Siemens",
                projectInfo: "- Interactive",
                descriptionHeading: "The brief",
                description: "Siemens were looking to add an automation interactive to their education portal. The interactive flows through each phase of a warehouse from the office to the factory. ",
                software: "javacsript / gsap / edge animate / webGL",
                isVideo: true,
                isLink: true,
                videoLink: "https://designingthefuturegame.co.uk/",
                viewMoreButton: viewInter,
                img1:dev01_01,
                images: [
                    dev01_01,
                    dev01_02,
                    dev01_03,
                    dev01_04,
                    dev01_05,
                ],
            },

            client02: {
                header: "Siemens",
                projectInfo: "- Interactive",
                descriptionHeading: "The brief",
                description: "This collection of interactives gives the user the options to learn more about Cybersecurity, IoT and Digital Twins.Each moduel is broken down into education and interactive and a quiz at the end.",
                software:  "javacsript / gsap / html / css / edge animate / webGL",
                isVideo: true,
                isLink: true,
                videoLink: "http://siemens.zincmediadev.com/digital_industries/index.html",
                viewMoreButton: viewInter,
                img1:dev02_01,
                images: [
                    dev02_01,
                    dev02_02,
                    dev02_03,
                    dev02_04,
                    dev02_05,
                    dev02_06,
                    dev02_07,
                ],
            },

            client03: {
                header: "Mainman Label",
                projectInfo: "- Website",
                descriptionHeading: "The brief",
                description: "This site was created to house a podcast based on David Bowies earlier years with the Mainman Label.“Ziggy played guitar, jamming good with weird and gilly And the spiders from mars.“",
                software:  "wordpress / javascript / html / css",
                isVideo: true,
                isLink: true,
                videoLink: "https://mainmanlabel.com/",
                viewMoreButton: viewInter,
                img1:dev02_01,
                images: [
                    dev03_01,
                    dev03_02,
                    dev03_03,
                    dev03_04,
                    dev03_05,
                ],
            },

            client04: {
                header: "Druces",
                projectInfo: "- Website",
                descriptionHeading: "The brief",
                description: "Full redevelopment of the Druces WordPress site",
                software:  "wordpress / javascript / html / css",
                isVideo: true,
                isLink: true,
                videoLink: "https://www.druces.com/",
                viewMoreButton: viewInter,
                img1:dev04_01,
                images: [
                    dev04_01,
                    dev04_02,
                    dev04_01
                ],
            }
        }
    },
    {
        index: 2,
        section: "videoSection",
        url: '/video',
        mainClass: "skillWrapper3",
        className: "skillImageWrapper3",
        name: "VIDEO &",
        secName: "ANIMATION",
        videoPreview: aniPreview,
        shouldHide: false,
        graphic: aniImg,
        wipedelay: 1.0,
        width: 33.3,
        porfolio: {
            client01: {
                header: "Tideway",
                projectInfo: "- Video",
                descriptionHeading: "The brief",
                description: "",
                software: "after effect / photoshop / illustrator / audition ",
                isVideo: true,
                isLink: false,
                videoLink: vid01,
                viewMoreButton: viewvideo,
                img1:v01_01,
                images: [
                    v01_01,
                ],
            },

            client02: {
                header: "Zinc",
                projectInfo: "- Showreel",
                descriptionHeading: "The brief",
                description: "",
                software: "unity /  blender /  google street view / after effects / html / css ",
                isVideo: true,
                isLink: false,
                videoLink: vid02,
                viewMoreButton: viewvideo,
                img1:v02_01,
                images: [
                    v02_01,
                ],
            },
            client03: {
                header: "The Body Shop",
                projectInfo: "- Educational Videos",
                descriptionHeading: "The brief",
                description: "",
                software: "unity /  blender /  google street view / after effects / html / css ",
                isVideo: true,
                isLink: false,
                videoLink: vid03,
                viewMoreButton: viewvideo,
                img1:v03_01,
                images: [
                    v03_01,
                ],
            },
            client04: {
                header: "CTC",
                projectInfo: "- Instructional Video",
                descriptionHeading: "The brief",
                description: "",
                software: "unity /  blender /  google street view / after effects / html / css ",
                isVideo: true,
                isLink: false,
                videoLink: vid04,
                viewMoreButton: viewvideo,
                img1:v04_01,
                images: [
                    v04_01,
                ],
            },
        }
    },
]
