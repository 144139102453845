import React, { useEffect, useRef } from 'react'
import { Power2 } from 'gsap'
import { gsap } from 'gsap'
import CSSRulePlugin from 'gsap/CSSRulePlugin';

import classes from '../../../containers/Skills/Skills.module.scss'
import './Close.scss'

const Close = (props) => {

    gsap.registerPlugin(CSSRulePlugin);


    let mainMenuCopy = useRef(null);
    let span1 = useRef(null);
    let span2 = useRef(null);
    let span3 = useRef(null);

    useEffect(()=> {
        gsap.to(mainMenuCopy, {x:"0px",delay:0.5, duration: 0.5, ease: Power2.easeOut})
        // gsap.from(span1, {x:"50px",opacity:0, delay:0.5, duration: 0.4, ease: Power2.easeOut})
        // gsap.from(span2, {x:"50px",opacity:0, delay:0.5, duration: 0.4, ease: Power2.easeOut})
        // gsap.from(span3, {x:"50px",opacity:0, delay:0.5, duration: 0.4, ease: Power2.easeOut})
    },[])
    
    const closeAnimation = () => {
        gsap.to(mainMenuCopy, {x:"200px", duration: 1, ease: Power2.easeOut})
        gsap.to(span1, {rotate:0, delay:0, duration: 0.2, ease: Power2.easeOut})
        gsap.to(span2, {rotate:0, duration: 0.2, ease: Power2.easeOut})
        gsap.to(span3, {rotate:0, delay:0, duration: 0.2, ease: Power2.easeOut})
    }


    return (
        <div
            className={props.className}
            onClick={
            ()=> {
                props.closePortfolioHandler()
                closeAnimation()
            }}
        >
            <div className="navInfo">
                <p ref={el => mainMenuCopy = el}>{props.text}</p>
            </div>
            <div className="closeIcon">
                <span ref={el => span1 = el}></span>
                <span ref={el => span2 = el}></span>
                <span ref={el => span3 = el}></span>
            </div>
        </div>
    )
}

export default Close