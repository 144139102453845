import React, { useEffect, useRef, useState } from 'react'
import Client from '../Client/Client'

import './Porfolio.scss'
import Aux from '../../HOC/Auxillary/Auxillary'
import gsap, { TimelineLite,Power2, Power4 } from 'gsap/gsap-core';

import Close from '../../components/UI/Close/Close'

import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import CSSRulePlugin from 'gsap/CSSRulePlugin';

const Porfolio = (props) => {

    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin)
    gsap.registerPlugin(CSSRulePlugin);

    const [toggleSlides, setSlideToggle] = useState(false);

    const revealRef = useRef([]);
    revealRef.current = []

    const [showClientGallery, toggleShowClientGallery] = useState(false)
    const [headerAnimation, toggleHeaderAnimation] = useState(true)
    const [clientVisible, setClientVisible] = useState([])
    const [videoVisible, setVideoVisible] = useState([false])
    const [videoSrc, setVideoSrc] = useState()
    const [showVideoPlayer, toggleShowVideoPlayer] = useState(false);
    const [windowOffset, toggleWindowOffset] = useState(0);


    const portfolioList = []
    const portfolioListInfo = []
    
    var objArray = Object.entries(props.property.porfolio)

    objArray.forEach(([key, value]) => {
        portfolioList.push(value.img1);
        portfolioListInfo.push(value)
    })

    const slides = portfolioListInfo;


    useEffect(()=> {
        toggleHeaderAnimation(false)
    },[headerAnimation])
    
    let currentSlide=null;

    useEffect(() => {
        ScrollTrigger.matchMedia({
            
        //desktop
        "(min-width: 800px)": function() {
            revealRef.current.forEach((el, index) => {
                gsap.fromTo(el, {
                    autoAlpha: 1,
                    x: "2px"
                }, {
                    duration: 1,
                    autoAlpha: 1,
                    ease: "none", 
                    x: "0px",
                    visibility:"hidden",
                    
                    scrollTrigger: {
                        id: `section-${index + 1}`,
                        trigger: el,
                        start: '-=400px center',
                        end: "bottom top",
                        toggleActions: 'restart none none reverse',
                        markers: false,
                        duration: 4,
                        ease: "Power4.inOut",
                        scrub: 1,
                        onEnter: () => {
                            scrollToElement(el, index)  
                            currentSlide = el;
                        },
                      
                    },
                   
                    
                });
                
            });
        },

        // mobile
        "(max-width: 799px)": function() {
            revealRef.current.forEach((el, index) => {
                gsap.fromTo(el, {
                    autoAlpha: 1,
                    x: "2px"
                }, {
                    duration: 1,
                    autoAlpha: 1,
                    ease: "none", 
                    x: "0px",
                    visibility:"hidden",
                    
                    scrollTrigger: {
                        id: `section-${index + 1}`,
                        trigger: el,
                        start: '+=0px center',
                        end: "bottom top",
                        toggleActions: 'restart none none reverse',
                        markers: false,
                        duration: 4,
                        ease: "Power4.inOut",
                        scrub: 1,
                        onEnter: () => {
                            scrollToElement(el, index)  
                            currentSlide = el;
                            
                        },
                        onEnterBack : ()=> {
                            scrollToElement(el, index)  
                            currentSlide = el;
                            
                        }
                    },
                   
                    
                });
                
            });
        },
            
        // all 
        "all": function() {
            // ScrollTriggers created here aren't associated with a particular media query,
            // so they persist.
        }
            
        }); 

       
    }, []);


    const scrollToElement = (currentEl) => {
        gsap.to(window, { scrollTo: currentEl, duration: 2, ease: "Power2.easeOut", })
        
    }

    const toggleVideoVisible = () => {
        const currentState = videoVisible;
        setVideoVisible(!currentState);
    }

    const toggleGallery = () => {
        toggleShowClientGallery(true)
        setVideoVisible(false)
    }

    const toggleGalleryInformationHandler = () => {
        // props.toggleGalleryInformationHandler(); 
    }

    const addToRefs = (el) => {
        if (el && !revealRef.current.includes(el)) {
            revealRef.current.push(el)
        }
    }

    const showVideo = () => {
        document.querySelector('.porfolioCloseBtn').classList.remove('hidden');
        document.body.setAttribute('style', ``)
        window.scrollTo(0, windowOffset)
        toggleShowVideoPlayer(false)
    }

    let currentVideo = null;
    let currentClientIndex = 0;

    const vidRef = useRef(null);

    const playVideo = (value) => {
        currentClientIndex = value;
        
        toggleVideoVisible();
        toggleWindowOffset(window.scrollY)
        document.body.setAttribute('style', `position: fixed; top: -${windowOffset}px; left: 0; right: 0`)
        setVideoSrc(slides[currentClientIndex].videoLink)
        toggleShowVideoPlayer(true)
        document.querySelector('.porfolioCloseBtn').classList.add('hidden');
        setTimeout(()=>{
            vidRef.current.play();
        },500)
    } 

    const openLink = (value) => {   
        currentClientIndex = value;  
        window.open(slides[currentClientIndex].videoLink , "_blank")
    }
    
    const pauseVideo = () => {     
        vidRef.current.pause();
    }
    return (
        <Aux>
            <div className="portiWrapper">   
                {showVideoPlayer && ( 
                    <div className="videoWrapper" >   
                        <div className="videoPlayer">
                                <Close
                                    closePortfolioHandler={()=>showVideo()}
                                    className={"videoCloseBtn"}
                                    text ={"close video"}
                                />
                            <div className="close-video-modal" onClick={()=>{showVideo()}}></div>    
                            <video className="videoContainer" ref={vidRef} width='96%' height='auto%' muted controls >
                                <source src={videoSrc} />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                       
                    </div>  
                )}
                {slides.map((value, index) => {            
                    return (
                        <div
                            className=" porfolioItem container"      
                            data-id={index}
                            ref={addToRefs}
                            key={index}
                        > 
                            {clientVisible && (<Client 
                                currentClientIndex = {index}
                                mainImage={value.img1}
                                galleryList={value.images}
                                header={value.header}
                                description={value.description}
                                software={value.software}
                                projectInfo={value.projectInfo}
                                isVideo= {value.isVideo}
                                videoLink = {value.videoLink}
                                viewMoreButton ={value.viewMoreButton}
                                isLink={value.isLink}
                                portfolioListInfo={props.portfolioListInfo}
                                videoVisible = {videoVisible}
                                toggleVideoVisible = {toggleVideoVisible}
                                openLink={() => openLink(index)}
                                playVideo={()=>playVideo(index)}
                                showClientGallery={showClientGallery}
                                headerAnimation={headerAnimation}
                                toggleGallery={toggleGallery}
                                closeGalleryInformation = {props.closeGalleryInformation}
                                toggleGalleryInformationHandler = {toggleGalleryInformationHandler}
                            />                                    
                            )}
                        </div>
                    )
                })}
            </div>
        </Aux>
    )
}

export default Porfolio