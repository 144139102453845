import React from 'react'
import classes from './Toolbar.module.css'
import Logo from '../Logo/Logo'
import { Route, Link } from 'react-router-dom';

const toolbar = (props) => {

    return (
        <div className={classes.header}>
                <div className={classes.logo}>
                <Link to="/">
                    <Logo />
                </Link >
                </div>
                {props.portfolioSection && (
                    <div className={classes.projectLink} onClick={props.portfolioSectionOpen}>        
                    </div>
                )}
        </div>
    )
}

export default toolbar 