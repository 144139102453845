import React from 'react'
import Lottie from 'react-lottie-player'

import './Loading.scss'
import loadingAnimation from '../../assets/ui/loading-logo.json'
const LoadingScreen = () => {
    return(
        <div className="logo-container">
            <Lottie
                className="loading-logo"
                loop={false}
                play={true}
                animationData={loadingAnimation}   
            />
        </div>
    )
}

export default LoadingScreen;