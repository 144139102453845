import React, { useRef, useEffect, useState, Component } from 'react'

import Cursor from '../../components/UI/Cursor/Cursor'
import Close from '../../components/UI/Close/Close'
import Skill from './Skill/Skill'

import '../Portfolio/Porfolio.css'
import { skillData } from '../../Data/data.js'

import './Skills.scss'
import './Skill/Skill.scss'

import Aux from '../../HOC/Auxillary/Auxillary'
import CSSRulePlugin from 'gsap/CSSRulePlugin';
import { Power2, TimelineLite } from 'gsap'
import { gsap } from 'gsap'

class Skills extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            arSection: false,
            devSection: false,
            videoSection: false,
            showVideoPreview: false,
            showPorfolioItems: false,
            closeGalleryInformation: true,
        }
    }
    componentDidMount() {
        this.blockAnimation();
    }

    porfolioVisibleHandler = (value, value2) => {
        this.setState({ showVideoPreview: false })
        this.setState({ showPorfolioItems: true })
        this.setState({ skillSelection: value }, () => {
            this.blockAnimation();
        });
    }

    closePortfolioHandler = () => {
        this.setState({ showVideoPreview: false })
        this.setState({ closeGalleryInformation: true })
        setTimeout(() => {
            this.setState({ portfolioSection: false },
                () => {
                    this.blockAnimation();
                    this.toggleAnimationDone()

                });
        }, 1000)
    }

    toggleAnimationDone = () => {
        this.setState({ showPorfolioItems: false })
    }

    toggleSection = (index) => {
        this.props.setSection(index)
        this.setState({ portfolioSection: true })
    }

    toggleVideoPreview = (section) => {
        this.setState({ showVideoPreview: true })
        this.setState({[section] :  true})
    }

    toggleVideoPreviewOff = () => {   
        this.setState({ showVideoPreview: false })
        this.setState({ arSection: false })
        this.setState({ devSection: false })
        this.setState({ videoSection: false })
    }

    setSelectedSections = () => {
        this.setState({ arSection: false })
        this.setState({ devSection: false })
        this.setState({ videoSection: false })
        this.setState({ showVideoPreview: true })
    }


    blockAnimation = () => {

        const tl = new TimelineLite()
        //Assign the correct animation wrapper for animation. 
        let skillWrappers = [".skillImageWrapper", ".skillImageWrapper2", ".skillImageWrapper3"]

        skillWrappers.splice(this.state.skillSelection, 1);

        const imageReveal1 = CSSRulePlugin.getRule(".skillImageWrapper");
        const imageReveal2 = CSSRulePlugin.getRule(".skillImageWrapper2");
        const imageReveal3 = CSSRulePlugin.getRule(".skillImageWrapper3");
        const skill1 = CSSRulePlugin.getRule(".skillWrapper1");
        const skill2 = CSSRulePlugin.getRule(".skillWrapper2");
        const skill3 = CSSRulePlugin.getRule(".skillWrapper3");
        const portfolioItem = CSSRulePlugin.getRule(".profolioList")


        if (this.state.portfolioSection) {
            tl.fromTo(imageReveal3, { width: imageReveal3.width }, { delay: 0, duration: 0.5, width: "0%", ease: Power2.easeInOut },0)
                .fromTo(imageReveal2, { width: imageReveal2.width }, {delay: 0, duration: 0.5, width: "0%", ease: Power2.easeInOut },0)
                .fromTo(imageReveal1, { width: imageReveal1.width }, {delay: 0, duration: 0.5, width: "0%", ease: Power2.easeInOut },0)
                .to(skill1, { duration: 0, display: "none", visibility: "hidden" }, 1)
                .to(skill2, { duration: 0, display: "none", visibility: "hidden" }, 1)
                .to(skill3, { duration: 0, display: "none", visibility: "hidden" }, 1)
                .to(portfolioItem, { duration: 1, width: "100%", ease: Power2.easeInOut, delay: 0 }, 0)
        } else {

            tl.to(skill1, { duration: 0, display: "block", visibility: "visible" }, 0)
                .to(skill2, { duration: 0, display: "block", visibility: "visible" }, 0)
                .to(skill3, { duration: 0, display: "block", visibility: "visible" }, 0)
                .to(imageReveal1, { duration: 0, display: "block", visibility: "visible" }, .5)
                .to(imageReveal2, { duration: 0, display: "block", visibility: "visible" }, .5)
                .to(imageReveal3, { duration: 0, display: "block", visibility: "visible" }, .5)
                .to(imageReveal1, { duration: 1, width: "100%", ease: Power2.easeInOut, }, 0)
                .to(imageReveal2, { duration: 1, width: "100%", ease: Power2.easeInOut }, 0)

                .to(imageReveal3, {
                    duration: 1,
                    width: "100%",
                    ease: Power2.easeInOut,
                    onComplete: this.setSelectedSections
                }, 0)

                .to(portfolioItem, { duration: 1, width: "0%", ease: Power2.easeInOut, delay: 0 }, 0)
        }
    }

    portfolionAnimateOut = () => {
        const imgWrapper2 = CSSRulePlugin.getRule(".slick-slide div");
        const campaignInfo = CSSRulePlugin.getRule(".campaingCopy");
        const prev = CSSRulePlugin.getRule(".left");
        const next = CSSRulePlugin.getRule(".right");

        gsap.fromTo(imgWrapper2, { width: "100%" }, { delay: 0, width: "0%", duration: 0.5 });
        gsap.fromTo(campaignInfo, { opacity: 1, y: "0px" }, { delay: 0, opacity: 0, y: "50px", duration: 1, ease: Power2.easeOut })
        gsap.fromTo(prev, { opacity: 1, x: "0px" }, { delay: 0, opacity: 1, x: "50px", duration: 1, ease: Power2.easeOut })
        gsap.fromTo(next, { opacity: 1, x: "0px" }, { delay: 0, opacity: 1, x: "-50px", duration: 1, ease: Power2.easeOut })

        gsap.set(campaignInfo, { opacity: 0 })
    }

    toggleGalleryInformationHandler = () => {

        this.setState({ closeGalleryInformation: false })

    }

    render() {
        gsap.registerPlugin(CSSRulePlugin);
        
        return (
            <Aux>
              
                <div className="mainWrapper col-12">
                    {this.props.skillData.map((value, index) => (
                        
                        <Skill
                            key={skillData[index].name}
                            property={skillData[index]}
                            url={value.url}
                            porfolioVisible={this.porfolioVisibleHandler}
                            updateSection={()=>this.toggleSection(index)}
                            index={index}
                            section={value.section}
                            currentSection ={this.state[value.section]}
                            showVideoPreview={this.state.showVideoPreview}
                            showPorfolioItems={this.state.showPorfolioItems}

                            toggleVideoPreview={this.toggleVideoPreview}
                            toggleVideoPreviewOff={this.toggleVideoPreviewOff}

                            toggleGalleryInformationHandler={this.toggleGalleryInformationHandler}
                            closeGalleryInformation={this.state.closeGalleryInformation}
                        />
                    ))}

                </div>
            </Aux>
        )
    }
}

export default Skills